.container
	height: 60px
	border-radius: 8px
	background: #fff
	padding: 10px 20px
	box-sizing: border-box
	overflow: hidden
	position: relative

.container.error
	background: rgba(241, 92, 92, 0.15)
	border: 1px solid rgba(241, 92, 92, 0.6)
	border-radius: 8px

.icon:global.MuiBox-root
	margin-right: 12px

.fileName:global.MuiTypography-root
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis

.text:global.MuiTypography-root
	color: #6E7385

.text.error
	color: #F15C5C

.progress:global.MuiLinearProgress-root
	position: absolute
	bottom: 0
	left: 0
	right: 0
	height: 2px
