.container
	min-width: 300px
	min-height: 140px
	background: #FFFFFF
	border: 1px dashed #DBDFE6
	box-shadow: 0px 6px 14px rgba(14, 15, 25, 0.02)
	border-radius: 8px
	transition: all 0.2s ease-in-out

.container:hover
	cursor: pointer

.container:hover,
.container.isDragActive
	background: darken(#FFFFFF, 10%)
	border-style: solid

.browse b
	text-decoration-line: underline
